import {Component, ElementRef, Optional, Renderer2, ViewChild} from '@angular/core';
import {TRANSLATION_CRUD_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {PasswordPolicy, User} from '../../../../types';
import {PasswordService} from '../../services/password.service';
import {UserService} from '../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RegistryService} from '@ngmedax/registry';
import {LoginService} from '@ngmedax/login';


/**
 * Component to reset password for user
 */
@Component({
  selector: 'app-user-crud',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
@Translatable({scope: TRANSLATION_CRUD_SCOPE, keys: KEYS})
export class ResetPasswordComponent {
  /**
   * Reset password card element ref
   * @type {ElementRef}
   */
  @ViewChild('resetPwCard') resetPwCard: ElementRef;

  /**
   * Reset password overlay element ref
   * @type {ElementRef}
   */
  @ViewChild('resetPwOverlay') resetPwOverlay: ElementRef;

  /**
   * Password policy
   */
  public passwordPolicy: PasswordPolicy;

  /**
   * User
   */
  public user: User;

  public constructor(
    @Optional() public translationService: TranslationService,
    @Optional() public loginService: LoginService,
    public userService: UserService,
    protected registryService: RegistryService,
    private passwordService: PasswordService,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private router: Router,
  ) {
    this.passwordPolicy = this.passwordService.getPasswordPolicy();

    this.activatedRoute.params.subscribe(async (params: {email: string, token: string, locale: string, id: number}) => {
      const userId = params.id;
      const email = params.email;
      const locale = params.locale;
      const token = params.token;

      try {
        const isValidResetToken = await this.userService.isValidResetToken(token);
        if (!isValidResetToken) {
          this.router.navigate(['module', 'login']).then().catch();
          return;
        }
      } catch(error) {
        console.error(error);
      }

      this.loginService.setTokenUser({userId, email, token, validUntil: <any>new Date(new Date().getTime() + 1)});
      this.translationService && this.translationService.setLocale(locale);
      this.registryService.set('forcePwChange', true);

      this.user = {
        userId, email, token,
        lastChangeAt: null,
        lastChangeBy: null,
      }
    });
  }

  /**
   * Called on password changed event. Fades out reset password card
   */
  public onPasswordChanged() {
    setTimeout(() => {
      this.switchClasses(this.resetPwCard, ['fadeOut']);
    }, 1000);

    setTimeout(() => {
      this.switchClasses(this.resetPwOverlay, ['fadeOut']);
    }, 1500);
  }

  /**
   * Switches given element ref from previous classes to given ones
   *
   * @param {ElementRef} el
   * @param classes
   */
  private switchClasses(el: ElementRef, classes) {
    this.removeClasses(el, ['flipInX', 'shake', 'pulse', 'infinite', 'fadeIn', 'fadeOut']);
    this.addClasses(el, classes);
  }

  /**
   * Removes given css classes from given element ref
   *
   * @param {ElementRef} el
   * @param {any[]} classes
   */
  private removeClasses(el: ElementRef, classes: any[]) {
    for (const cls of classes) {
      this.renderer.removeClass(el.nativeElement, cls);
    }
  }

  /**
   * Adds given css classes to given element ref
   *
   * @param {ElementRef} el
   * @param {any[]} classes
   */
  private addClasses(el: ElementRef, classes: any[]) {
    for (const cls of classes) {
      this.renderer.addClass(el.nativeElement, cls);
    }
  }
}
