import {NgModule, Optional} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Router, NavigationEnd} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';

import {DynamicModule} from 'ng-dynamic-component';
import {DragulaModule} from 'ng2-dragula';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';

import {ExpressionCompilerModule} from '@ngmedax/expression-compiler';
import {ColorPickerModule} from 'ngx-color-picker';
import {Select2Module} from '@ngmedax/select2';

import {routing} from './questionnaire-editor.routing';
import {NAVIGATION} from './questionnaire-editor.navigation';

import {ConditionsEditorGroupComponent} from './components/question/conditions/editor/group/conditions-editor-group.component';
import {ConditionsEditorExpressionComponent}
  from './components/question/conditions/editor/expression/conditions-editor-expression.component';


import {QuestionnaireEditorComponent} from './questionnaire-editor.component';
import {QuestionComponent} from './components/question/question.component';
import {PageBreakComponent} from './components/page-break/page-break.component';
import {QuestionIndexComponent} from './components/question-index/question-index.component';
import {QuestionTypeMatrixComponent} from './components/question/question-types/question-type-matrix.component';
import {QuestionTypeChoiceComponent} from './components/question/question-types/question-type-choice.component';
import {QuestionTypeTextComponent} from './components/question/question-types/question-type-text.component';
import {QuestionTypeDateComponent} from './components/question/question-types/question-type-date.component';
import {QuestionTypeNumericComponent} from './components/question/question-types/question-type-numeric.component';
import {QuestionTypeCanvasComponent} from './components/question/question-types/question-type-canvas.component';
import {QuestionTypeSignatureComponent} from './components/question/question-types/question-type-signature.component';
import {QuestionTypeNetDiagramComponent} from './components/question/question-types/question-type-net-diagram.component';
import {AddQuestionComponent} from './components/add-question/add-question.component';
import {PreviewComponent} from './components/preview/preview.component';
import {IoOverlayComponent} from './components/io-overlay/io-overlay.component';
import {ConditionsComponent} from './components/question/conditions/conditions.component';
import {VariablesComponent} from './components/question/variables/variables.component';
import {ScoringModalComponent} from './components/question/scoring/modal/scoring-modal.component';
import {VariablesModalComponent} from './components/question/variables/modal/variables-modal.component';
import {AddQuestionModalComponent} from './components/add-question/modal/add-question-modal.component';
import {ScoringVariablesComponent} from './components/scoring-variables/scoring-variables.component';
import {ExternVariablesComponent} from './components/extern-variables/extern-variables.component';
import {FormulaVariablesComponent} from './components/formula-variables/formula-variables.component';
import {ExtendedConditionsComponent} from './components/question/conditions/extended/extended-conditions.component';
import {MapVariablesComponent} from './components/map-variables/map-variables.component';

import {QuestionnaireFormatService} from './services/questionnaire-format.service';
import {QuestionnaireEditorService} from './services/questionnaire-editor.service';
import {QuestionnaireStateService} from './services/questionnaire-state.service';
import {QuestionnaireVariablesService} from './services/questionnaire-variables.service';
import {QuestionnaireTabsService} from './services/questionnaire-tabs.service';
import {QuestionnaireMediaCenterService} from './services/questionnaire-media-center.service';
import {QuestionnaireConditionService} from './services/questionnaire-condition.service';

import {DomHelperService} from './services/dom-helper.service';
import {AuthUriService} from './services/auth-uri.service';
import {ApiService} from './services/api.service';
import {StriptagsPipe} from './pipes/striptags.pipe';
import {HtmlDecodePipe} from './pipes/htmldecode.pipe';
import {TruncatePipe} from './pipes/truncate.pipe';

import {LayoutService} from '@ngmedax/layout';
import {ConfigService} from '@ngmedax/config';
import {ErrorService} from '@ngmedax/error';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {TRANSLATION_EDITOR_SCOPE} from '../constants';
import {KEYS} from '../translation-keys';
import {PdfFormSelectModule} from '@ngmedax/pdf-form-select';
import {filter} from 'rxjs/operators';
import {IMaskModule} from 'angular-imask';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionnaireEditorModule extends Translatable {}

// @ts-ignore
@NgModule({
  entryComponents: [
    ScoringModalComponent,
    VariablesModalComponent,
    AddQuestionModalComponent,

    QuestionComponent,
    PageBreakComponent,
    QuestionTypeTextComponent,
    QuestionTypeNumericComponent,
    QuestionTypeDateComponent,
    QuestionTypeChoiceComponent,
    QuestionTypeMatrixComponent,
    QuestionTypeCanvasComponent,
    QuestionTypeSignatureComponent,
    QuestionTypeNetDiagramComponent,
    ScoringVariablesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    IMaskModule,
    DynamicModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    ExpressionCompilerModule,
    ColorPickerModule,
    Select2Module,
    PdfFormSelectModule,
    DragulaModule,
    routing
  ],
  declarations: [
    ConditionsEditorExpressionComponent,
    ConditionsEditorGroupComponent,
    QuestionnaireEditorComponent,
    AddQuestionModalComponent,
    QuestionIndexComponent,
    ScoringModalComponent,
    VariablesModalComponent,
    MapVariablesComponent,
    AddQuestionComponent,
    PreviewComponent,
    IoOverlayComponent,
    QuestionComponent,
    ConditionsComponent,
    ExtendedConditionsComponent,
    VariablesComponent,
    PageBreakComponent,
    ScoringVariablesComponent,
    ExternVariablesComponent,
    FormulaVariablesComponent,
    QuestionTypeTextComponent,
    QuestionTypeNumericComponent,
    QuestionTypeDateComponent,
    QuestionTypeChoiceComponent,
    QuestionTypeMatrixComponent,
    QuestionTypeCanvasComponent,
    QuestionTypeSignatureComponent,
    QuestionTypeNetDiagramComponent,
    StriptagsPipe,
    HtmlDecodePipe,
    TruncatePipe
  ],
  exports: [],
  providers: [
    QuestionnaireMediaCenterService,
    QuestionnaireEditorService,
    QuestionnaireStateService,
    QuestionnaireFormatService,
    QuestionnaireVariablesService,
    QuestionnaireTabsService,
    QuestionnaireConditionService,
    DomHelperService,
    AuthUriService,
    ApiService
  ]
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class QuestionnaireEditorModule {
  public constructor(
    private router: Router,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private errorService: ErrorService,
    @Optional() private translationService: TranslationService
  ) {
    layoutService.addMenuEntry(NAVIGATION);

    // subscribe to navigation end event
    const subscription = router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const questionnaireApiUri = this.configService.get('apis.questionnaire.uri');

        if (!questionnaireApiUri) {
          // unsubscribe to prevent redirect loop
          subscription.unsubscribe();
          this.showErrorMessage();
        }
      });
  }

  /**
   * Adds a "questionnaire api url not found" message to the error service and redirects to the error page.
   */
  private showErrorMessage() {
    // uri for the error message
    const uri = 'questionnaire-api-uri-not-configured';

    // title that we will see on the error page
    const title = this._(KEYS.EDITOR.CONFIG_ERROR);

    // message that we will see on the error page
    const message =
      this._(KEYS.EDITOR.QUESTIONNAIRE_API_URL_UNSET) +
      '<br/>';

    // example config object that we will se on the error page
    const exampleObject = {
      'apis': {
        'questionnaire': {
          'api': 'https://some.tld/api'
        }
      }
    };

    // add error message and redirect to error page
    // note: error service will only redirect when we are not already on this error page
    this.errorService
      .addErrorMessage(uri, title, message, exampleObject)
      .navigateToError(uri);
  }
}
