import {Component, ElementRef, OnInit, Optional, Renderer2, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Router} from '@angular/router';

import {LayoutService} from '@ngmedax/layout';
import {ErrorService} from '@ngmedax/error';
import {ConfigService} from '@ngmedax/config';
import {Translatable, TranslationService} from '@ngmedax/translation';

import {LoginService} from '../../service/login.service';
import {LoginConfigService} from '../../service/login.config.service';
import {TRANSLATION_LOGIN_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface LoginComponent extends Translatable {}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [
    {provide: 'TRANSLATION_SCOPE', useValue: TRANSLATION_LOGIN_SCOPE},
  ]
})
@Translatable({scope: TRANSLATION_LOGIN_SCOPE, keys: KEYS})
export class LoginComponent implements OnInit {
  /**
   * Pattern to check if an email address is valid
   * @type {RegExp}
   */
  private emailPattern = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;

  /**
   * Login overlay element ref
   * @type {ElementRef}
   */
  @ViewChild('loginOverlay') loginOverlay: ElementRef;

  /**
   * Login card element ref
   * @type {ElementRef}
   */
  @ViewChild('loginCard') loginCard: ElementRef;

  /**
   * Forgot password card element ref
   * @type {ElementRef}
   */
  @ViewChild('forgotPwCard') forgotPwCard: ElementRef;

  /**
   * Terms of service card element ref
   * @type {ElementRef}
   */
  @ViewChild('tosCard') tosCard: ElementRef;

  /**
   * Whenever or not to show the white login overlay
   * @type {boolean}
   */
  public showLoginOverlay = true;

  /**
   * Flag for the form to determine if we are loading or not
   * @type {boolean}
   */
  public loading = false;

  /**
   * Tenant name
   * @type {string}
   */
  public tenantName = '';

  /**
   * Login error message
   * @type {string}
   */
  public errorMsg: string;

  /**
   * The reactive login form
   * @type {FormGroup}
   */
  public loginForm: FormGroup;

  /**
   * The reactive forgot password form
   * @type {FormGroup}
   */
  public forgotPwForm: FormGroup;

  /**
   * Is the locale module available?
   * @type {boolean}
   */
  public readonly hasLocaleSupport: boolean = false;

  /**
   * Show forgot password card?
   * @type {boolean}
   */
  public showForgotPasswordCard: boolean = false;

  /**
   * Show tos card?
   * @type {boolean}
   */
  public showTosCard: boolean = false;

  /**
   * Captcha image url
   * @type {string}
   */
  public captchaImageUrl: SafeUrl;

  /**
   * Whenever or not the user can use the password reset feature
   * @type {boolean}
   */
  public canUsePasswordReset: boolean = false;

  /**
   * Injects some objects
   *
   * @param {FormBuilder} formBuilder
   * @param {Renderer2} renderer
   * @param {Router} router
   * @param {LoginService} loginService
   */
  constructor(
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private router: Router,
    private errorService: ErrorService,
    private loginService: LoginService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private loginConfigService: LoginConfigService,
    @Optional() private translationService: TranslationService
  ) {
    this.canUsePasswordReset = !!this.configService.get('feature.patient.app.mail');
    this.hasLocaleSupport = !!this.translationService;
    this.loadCaptcha();
  }

  /**
   * Removes given css classes from given element ref
   *
   * @param {ElementRef} el
   * @param {any[]} classes
   */
  private removeClasses(el: ElementRef, classes: any[]) {
    for (const cls of classes) {
      this.renderer.removeClass(el.nativeElement, cls);
    }
  }

  /**
   * Adds given css classes to given element ref
   *
   * @param {ElementRef} el
   * @param {any[]} classes
   */
  private addClasses(el: ElementRef, classes: any[]) {
    for (const cls of classes) {
      this.renderer.addClass(el.nativeElement, cls);
    }
  }

  /**
   * Switches given element ref from previous classes to given ones
   *
   * @param {ElementRef} el
   * @param classes
   */
  private switchClasses(el: ElementRef, classes) {
    this.removeClasses(el, ['flipInX', 'shake', 'pulse', 'infinite', 'fadeIn']);
    this.addClasses(el, classes);
  }

  private showError() {
    const uri = 'login-api-url-not-found';
    const title = KEYS.LOGIN.LOGIN;
    const message = KEYS.LOGIN.ERROR_UMS_API_URI_UNCONFIGURED;
    const exampleJson = {
      'apis': {
        'ums': {
          'uri': 'http://ums.domain.tld',
          'resources': {
            'login': '/auth/login'
          }
        }
      }
    };

    this.errorService
      .addErrorMessage(uri, title, message, exampleJson)
      .navigateToError(uri);
  }

  /**
   * Loads the captcha image
   */
  private loadCaptcha() {
    const captchaUrl = this.loginConfigService.getResetPasswordCaptchaUrl() + '?t=' + new Date().getTime();
    this.captchaImageUrl = this.sanitizer.bypassSecurityTrustUrl(captchaUrl);
  }

  /**
   * Timeout function
   * @param ms
   */
  private async timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  /**
   * Inits the login form
   */
  ngOnInit() {
    // get login url via login config service
    const loginApiUrl = this.loginConfigService.getUmsLoginUrl();

    if (!loginApiUrl) {
      this.showError();
    }

    // set current tenant name
    this.tenantName = this.loginConfigService.getTenantName() || '';

    // hide white login overlay when url ends with /out
    if (this.router.url.match(/\/out$/)) {
      this.showLoginOverlay = false;
    }

    // shortcut for form builder
    const fb = this.formBuilder;

    // build login form
    this.loginForm = fb.group({
      'username': [null, [
        Validators.required,
        Validators.pattern(this.emailPattern)
      ]],
      'password': [null, Validators.required],
      'remember': [true, null]
    });

    // build forgot password form
    this.forgotPwForm = fb.group({
      'usernameForgot': [null, [
        Validators.required,
        Validators.pattern(this.emailPattern)
      ]],
      'captcha': [null, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5)
      ]]
    });
  }

  /**
   * On show forgot password form
   */
  onShowForgotPwForm() {
    const username = this.loginForm.get('username').value;
    username && this.forgotPwForm.get('usernameForgot').setValue(username);
    this.showForgotPasswordCard = true;
  }

  /**
   * On form submit
   */
  async onSubmit() {
    // this will disable the submit button
    this.loading = true;

    // unset error message
    this.errorMsg = '';

    // pulsate login form to inform the user that we are doing something (loader replacement)
    this.switchClasses(this.loginCard, ['pulse', 'infinite']);

    try {
      const userLogin = await this.loginService.login(this.loginForm.value.username, this.loginForm.value.password, false);

      // this will re-enable the login button
      this.loading = false;

      // this will hide the white login overlay
      this.showLoginOverlay = false;

      // login successful => zoom out login form
      this.switchClasses(this.loginCard, ['zoomOut']);

      // set the username in the layout service (renders it in the user box)
      this.layoutService.setUser({username: userLogin.getUsername()});

      const isTosEnabled = this.configService.get('tos.enabled');
      const isTosAccepted = userLogin.getTosAccepted();

      if (isTosEnabled && !isTosAccepted) {
        await this.timeout(400);
        this.showTosCard = true;
      } else {
        await this.onLoginRedirect();
      }
    } catch (error: any) {
      this.loading = false;

      if (error.code === 4000) {
        this.errorMsg = KEYS.LOGIN.INVALID_CREDENTIALS;
      } else if (error.code === 403) {
        this.errorMsg = KEYS.LOGIN.INSUFFICIENT_PERMISSIONS;
      } else if (error.code === 99999) {
        this.errorMsg = KEYS.LOGIN.ERROR_UMS_API_UNREACHABLE;
      } else {
        this.errorMsg = KEYS.LOGIN.ERROR_UMS_API_ANSWER + ' ' + error.message;
      }

      // shake the login form
      this.switchClasses(this.loginCard, ['shake']);
    }
  }

  async onAcceptTos() {
    try {
      this.switchClasses(this.tosCard, ['pulse', 'infinite']);

      await this.loginService.acceptTos();
      await this.timeout(1000);

      this.switchClasses(this.tosCard, ['fadeOut']);

      await this.timeout(400);
      this.switchClasses(this.loginOverlay, ['fadeOut']);

      await this.onLoginRedirect();
    } catch (error) {
      alert(this.hasLocaleSupport ? this._(KEYS.LOGIN.ERROR_ACCEPTING_TOS) : KEYS.LOGIN.ERROR_ACCEPTING_TOS);
    }
  }

  async onDeclineTos() {
    try {
      await this.loginService.logout();
      this.switchClasses(this.tosCard, ['fadeOut']);
      await this.timeout(400);
      location.href = '/';
    } catch (error) {
      console.error(error);
    }
  }

  async onLoginRedirect() {
    try {
      this.loginService.persistUserObject();

      // animate the login overlay to fade out after 400ms
      await this.timeout(400);
      this.switchClasses(this.loginOverlay, ['fadeOut']);

      // redirect to previous uri after 700ms (time for the animation to run)
      await this.timeout(700);
      const targetUri = this.loginService.getPreviousUri() === '/module/login/out' ? '/' : this.loginService.getPreviousUri();
      await this.router.navigateByUrl(targetUri);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * On forgot password submit
   */
  async onSubmitForgotPw() {
    try {
      // this will disable the submit button
      this.loading = true;

      // unset error message
      this.errorMsg = '';

      // pulsate forgot pw form to inform the user that we are doing something (loader replacement)
      this.switchClasses(this.forgotPwCard, ['pulse', 'infinite']);

      const locale = this.hasLocaleSupport ? this.translationService.getLocale() : 'de_DE';
      const username = this.forgotPwForm.value.usernameForgot;
      const captcha = this.forgotPwForm.value.captcha;

      if (username === 'admin@mymedax.de' || username === 'allocator@mymedax.de') {
        this.switchClasses(this.forgotPwCard, ['shake']);
        this.errorMsg = this.hasLocaleSupport ?
          this._(KEYS.LOGIN.RESET_PASSWORD_DEFAULT_USER_NO_MAIL_ERROR) : KEYS.LOGIN.RESET_PASSWORD_DEFAULT_USER_NO_MAIL_ERROR;

        this.loadCaptcha();
        this.loading = false;
        return;
      }

      try {
        await this.loginService.resetPassword(username, captcha, locale);
        alert(this.hasLocaleSupport ? this._(KEYS.LOGIN.RESET_PASSWORD_MAIL_SUCCESS) : KEYS.LOGIN.RESET_PASSWORD_MAIL_SUCCESS);
        this.switchClasses(this.forgotPwCard, ['none']);
        this.showForgotPasswordCard = false;
      } catch (error) {
        console.error(error);
        this.switchClasses(this.forgotPwCard, ['shake']);
        const errorMsg = (<any>error).message || 'Unknown error';
        this.errorMsg = this.hasLocaleSupport ? this._(errorMsg) : errorMsg;
        this.loadCaptcha();
        this.loading = false;
        return;
      }
    } catch (error) {
      console.error(error);
    }

  }

  /**
   * Getter for the username form element
   * @returns {FormControl}
   */
  get username() {
    return this.loginForm.get(['username']);
  }

  /**
   * Getter for the password form element
   * @returns {FormControl}
   */
  get password() {
    return this.loginForm.get(['password']);
  }

  /**
   * Getter for the username form element
   * @returns {FormControl}
   */
  get usernameForgot() {
    return this.forgotPwForm.get(['usernameForgot']);
  }

  /**
   * Getter for the password form element
   * @returns {FormControl}
   */
  get captcha() {
    return this.forgotPwForm.get(['captcha']);
  }
}
