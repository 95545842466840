import {Injectable, Optional} from '@angular/core';
import {LoginService} from '@ngmedax/login';
import {ConfigService} from '@ngmedax/config';

/**
 * Service to deal with auth uri
 */
@Injectable()
export class AuthUriService {
  private resetToken: string;

  public constructor(private configService: ConfigService) {
  }

  /**
   * Returns auth uri part: e.g: /:token/:tenant
   *
   * Returns empty string if login not set. Also returns
   * empty string if no user returned by login service.
   *
   * @param {LoginService} loginService
   * @param {boolean} withTenantId
   * @returns {string}
   */
  public getAuthUri(
    @Optional() loginService: LoginService,
    withTenantId = false
  ): string {
    if (!loginService || !loginService.getUser) {
      return '';
    }

    const user = loginService.getUser();
    if (!user.getToken) {
      return '';
    }

    const token = user.getToken();
    const tenantId = this.configService.get('tenantId');
    return `/${token}` + (withTenantId ? `/${tenantId}` : '');
  }

  /**
   * Returns url with auth by given params
   *
   * @param {LoginService} loginService
   * @param {string} baseConfigKey
   * @param {string} configKey
   * @param {any} suffix
   * @returns {string}
   */
  public getUrlWithAuth(
    @Optional() loginService: LoginService,
    baseConfigKey: string,
    configKey: string,
    suffix = null
  ) {
    let baseUri = this.configService.get(baseConfigKey)
      + this.getAuthUri(loginService)
      + this.configService.get(configKey);

    if (suffix) {
      baseUri = `${baseUri}${suffix}`;
    }

    return baseUri;
  }
}
