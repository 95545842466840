<div class="qa-grid">
  <h2 class="mb-4">{{_(KEYS.GRID.SURVEYS)}}</h2>
  <div class="card mb-5">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-search"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.SEARCH)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isSearchCollapsed = !isSearchCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isSearchCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isSearchCollapsed">
        <div class="qa-grid-search mt-2 mb-2">
          <div class="input-group qa-grid-search-patient">
            <span class="input-group-addon">{{_(KEYS.GRID.PATIENT)}}:</span>
            <input class="form-control" [(ngModel)]="filter.patient"
                   (paste)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
          </div>
          <div class="input-group qa-grid-search-status">
            <span class="input-group-addon">{{_(KEYS.GRID.STATUS)}}:</span>
            <select class="custom-select" [(ngModel)]="filter.status"
                    (blur)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
              <option value="" selected>...</option>
              <option value="created">{{_(KEYS.GRID.STATUS_CREATED)}}</option>
              <option value="assigned">{{_(KEYS.GRID.STATUS_ASSIGNED)}}</option>
              <option value="submitted">{{_(KEYS.GRID.STATUS_SUBMITTED)}}</option>
              <option value="signed">{{_(KEYS.GRID.STATUS_SIGNED)}}</option>
            </select>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-copy"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.SURVEYS)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isGridCollapsed = !isGridCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isGridCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isGridCollapsed">
        <table class="table">
          <thead>
          <tr>
            <th class="id-col">{{_(KEYS.GRID.ID)}}</th>
            <th>{{_(KEYS.GRID.PATIENT)}}</th>
            <th>{{_(KEYS.GRID.QUESTIONNAIRES)}}</th>
            <th>{{_(KEYS.GRID.SUBMISSIONS)}}</th>
            <th>{{_(KEYS.GRID.STATUS)}}</th>
            <th>{{_(KEYS.GRID.HISTORY)}}</th>
            <th>{{_(KEYS.GRID.CREATED_AT)}}</th>
            <th>{{_(KEYS.GRID.UPDATED_AT)}}</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let survey of surveys; let pos = index;">
            <ng-template #notFound>
              <div ngbTooltip="{{_(KEYS.GRID.RELATED_DATA_NOT_AVAILABLE)}}">
                <i class="fa fa-times"></i>
              </div>
            </ng-template>

            <tr [ngClass]="{'qa-tr-odd': pos % 2 === 0}">
              <td class="id-col">
                <span ngbTooltip="{{survey.uid}}">{{survey.uid | truncate: 5}}</span>
              </td>
              <td>
                <ng-container *ngIf="!survey?.client?.anonymous && !survey?.client?.pseudonymous">
                  <ng-container *ngIf="survey.client; else notFound">
                    {{_(KEYS.GRID.PATIENT_ID)}} {{survey?.client?.customerNr}} -
                    {{_(KEYS.GRID.CASE_NR)}} {{survey?.client?.caseNr}} -
                    *{{fromMySQLDate(survey.client.birthDate)}} - {{survey?.client?.gender === 'male' ? 'm': 'w'}} <br>
                    <ng-container *ngIf="survey?.client?.pseudoId">
                      Pseudo Id - {{survey.client.pseudoId}}<br>
                    </ng-container>
                    <span *ngIf="survey.client.gender === 'male'">
                      {{_(KEYS.GRID.MR)}}
                    </span>
                    <span *ngIf="survey.client.gender === 'female'">
                      {{_(KEYS.GRID.MS)}}
                    </span>
                    {{survey.client.firstName}} {{survey.client.lastName}}
                    {{survey.client.address}} - {{survey.client.location}} - {{survey.client.status}}
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="survey?.client?.anonymous">
                  {{_(KEYS.GRID.ANONYMOUS)}}
                </ng-container>
                <ng-container *ngIf="survey?.client?.pseudonymous">
                  {{_(KEYS.GRID.PSEUDONYMOUS)}} {{survey?.client?.pseudoId ? '- Pseudo Id: '+survey?.client?.pseudoId : ''}}
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="survey.questionnaires">
                  <ul>
                    <ng-container *ngFor="let questionnaire of survey.questionnaires">
                      <li *ngIf="questionnaire?.meta?.title && questionnaire?.meta?.title[locale]">
                        {{questionnaire.meta.title[locale]}}
                      </li>
                    </ng-container>
                  </ul>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="survey.submissions">
                  <ul>
                    <ng-container *ngFor="let submission of survey.submissions">
                      <li *ngIf="submission?.questionnaire?.meta?.title && submission?.questionnaire?.meta?.title[locale]">
                        <div ngbDropdown class="d-inline-block" [attr.disabled]="!submission.exports ? true : null">
                          <button class="btn btn-primary btn-xs d-inline-block mr-1" ngbDropdownToggle>
                            <i class="fa fa-download"></i>
                          </button>
                          <div ngbDropdownMenu>
                            <button
                              class="dropdown-item" *ngIf="submission.bucketId" (click)="onOpenMediaCenterModal(submission)">
                              <i class="fa fa-window-maximize"></i> {{_(KEYS.GRID.ASSETS)}}
                            </button>

                            <button
                              class="dropdown-item"
                              (click)="onOpenLink(submission.uploadLinks['ownCloud'])"
                              *ngIf="submission.uploadLinks && submission.uploadLinks['ownCloud']"
                            >
                              <i class="fa fa-cloud"></i> ownCloud
                            </button>
                            <hr *ngIf="(submission.bucketId) || (submission.uploadLinks && submission.uploadLinks['ownCloud'])"/>

                            <ng-container *ngIf="hasValidExports(submission)">
                              <ng-container *ngFor="let export of submission.exports">
                                <button class="dropdown-item" (click)="onDownload(export.fileName)" *ngIf="export.type !== 'EXTERN'">
                                  <i class="fa fa-download"></i>&nbsp;&nbsp;{{export?.type}}
                                </button>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>

                        {{submission.questionnaire.meta.title[locale]}}
                      </li>
                    </ng-container>
                  </ul>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="!survey.status || survey.status === 'unknown'">
                  <i class="fa fa-question text-secondary" ngbTooltip="{{_(KEYS.GRID.STATUS_UNKNOWN)}}"></i>
                </ng-container>
                <ng-container *ngIf="survey.status === 'created'">
                  <i class="fa fa-plus text-secondary" ngbTooltip="{{_(KEYS.GRID.STATUS_CREATED)}}"></i>
                </ng-container>
                <ng-container *ngIf="survey.status === 'assigned'">
                  <i class="fa fa-user text-primary" ngbTooltip="{{_(KEYS.GRID.STATUS_ASSIGNED)}}"></i>
                </ng-container>
                <ng-container *ngIf="survey.status === 'saved'">
                  <a href="{{getMobileFrontendLink(survey)}}" class="saved-link" target="_blank">
                    <i class="fa fa-save text-primary" ngbTooltip="{{_(KEYS.GRID.STATUS_SAVED)}}"></i>
                  </a>
                </ng-container>
                <ng-container *ngIf="survey.status === 'submitted'">
                  <i class="fa fa-check text-success" ngbTooltip="{{_(KEYS.GRID.STATUS_SUBMITTED)}}"></i>
                </ng-container>
                <ng-container *ngIf="survey.status === 'signed'">
                  <i class="fa fa-pencil text-success" ngbTooltip="{{_(KEYS.GRID.STATUS_SIGNED)}}"></i>
                </ng-container>
                <ng-container *ngIf="survey.status === 'canceled'">
                  <i class="fa fa-ban text-danger" ngbTooltip="{{_(KEYS.GRID.STATUS_CANCELED)}}"></i>
                </ng-container>
              </td>
              <td>
                <button class="btn btn-sm" (click)="onOpenHistory(survey)">
                  <i class="fa fa-history"></i>
                </button>
              </td>
              <td style="min-width: 135px;">
                {{survey.createdAt | datex:dateFormat}}
              </td>
              <td style="min-width: 135px;">
                {{survey.updatedAt | datex:dateFormat}}
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
        <div class="btn-toolbar justify-content-between mt-2 mb-2">
          <div class="justify-content-between">
            <div class="input-group">
              <div class="input-group qa-num-per-page">
                <select class="form-control custom-select" [(ngModel)]="displayPerPage" (change)="onPagingChange()">
                  <option value="2">2</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span class="input-group-addon">{{_(KEYS.GRID.ENTRIES)}}</span>
              </div>
            </div>
          </div>
          <ngb-pagination
            [pageSize]="displayPerPage"
            [maxSize]="9"
            [rotate]="true"
            [(page)]="gridPageNumber"
            [collectionSize]="total"
            (pageChange)="onPagingChange()">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
