import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserLoginModel} from '../user.login.model';
import {LoginConfigService} from './login.config.service';
import {AuthUriService} from './auth-uri.service';
import {LayoutService} from '@ngmedax/layout';
import {RegistryService} from '@ngmedax/registry';
import * as sha from 'sha.js';
import {LoginEventService} from './login-event.service';


@Injectable()
export class LoginService {
  private static userObject: UserLoginModel = null;
  private static previousUri = '/';
  private readonly initialPw = 'mymedax';

  /**
   * Injects Dependencies
   */
  public constructor(
    private loginEventService: LoginEventService,
    private loginConfigService: LoginConfigService,
    private registryService: RegistryService,
    private authUriService: AuthUriService,
    private layoutService: LayoutService,
    private http: HttpClient
  ) {
  }

  /**
   * Returns password hash by converting it to a binary
   * sha256 hash, which is then converted to base64
   *
   * @param password
   * @returns {string}
   */
  private getPasswordHash(password) {
    const u8Arr = sha('sha256').update(password).digest();
    const CHUNK_SIZE = 0x8000;
    let index = 0;
    const length = u8Arr.length;
    let result = '';
    let slice;
    while (index < length) {
      slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length));
      result += String.fromCharCode.apply(null, slice);
      index += CHUNK_SIZE;
    }
    return btoa(result);
  }

  /**
   * Stores user object in local storage
   */
  public persistUserObject() {
    if (!localStorage) {
      return;
    }

    if (!LoginService.userObject) {
      return;
    }

    const jsonUserObject = JSON.stringify(LoginService.userObject.getRaw());
    localStorage.setItem('userLoginObject', jsonUserObject);
  }

  /**
   * Checks if user is persisted
   *
   * @returns {boolean}
   */
  private isUserPersisted() {
    if (!localStorage) {
      return false;
    }

    return !!localStorage.getItem('userLoginObject');
  }

  /**
   * Removes user object from local storage
   */
  private deleteUserObject() {
    localStorage.removeItem('userLoginObject');
  }

  /**
   * Returns true if user session is expired
   * @param {UserLoginModel} user
   */
  private isSessionExpired(user: UserLoginModel) {
    let isExpired = true;

    const now = new Date().getTime();
    const sessionExpireTime = user.getValidUntil().getTime();

    if (sessionExpireTime > now) {
      isExpired = false;
    }

    return isExpired;
  }

  /**
   * Gets logged in user
   * @returns {UserLoginModel}
   */
  public getUser(): UserLoginModel {
    // get user object by static member
    let userObject = LoginService.userObject;

    // if static member is empty and the local storage object is present
    if (!userObject && localStorage) {
      // try to get the user object from local storage
      const jsonUserObject = localStorage.getItem('userLoginObject');

      // if we found a persisted user object
      if (jsonUserObject) {
        // convert from json to object
        const userData = JSON.parse(jsonUserObject);

        if (userData === null) {
          return null;
        }

        userObject = new UserLoginModel(
          userData.username,
          userData.token,
          userData.userId,
          userData.validUntil,
          userData.permissions,
          userData.data,
          userData.tosAccepted
        );

        // return null if user session is expired
        if (this.isSessionExpired(userObject)) {
          return null;
        }

        // save user object from storage in static user object member
        LoginService.userObject = userObject;
      }
    }

    // return found user object
    return userObject;
  }

  public setTokenUser(user: {email: string, token: string, userId: any, validUntil: string}) {
    LoginService.userObject = new UserLoginModel(
      user.email,
      user.token,
      user.userId,
      user.validUntil,
      [],
      true,
      {}
    );
  }

  /**
   * Checks if the user is logged in and returns true of false.
   * @returns {boolean}
   */
  public isLoggedIn(): boolean {
    return (this.getUser() instanceof UserLoginModel);
  }

  /**
   * Sets the previous url
   * @param {string} previousUri
   */
  public setPreviousUri(previousUri: string) {
    LoginService.previousUri = previousUri;
  }

  /**
   * Returns the previous url
   * @returns {string}
   */
  public getPreviousUri(): string {
    return LoginService.previousUri;
  }

  /**
   * Logs a user into the ums
   *
   * @param username
   * @param password
   * @param remember
   * @returns {Promise<any>}
   */
  public login(username, password, remember): Promise<any> {
    return new Promise((resolve, reject) => {
      // get login url via login config service
      const loginApiUrl = this.loginConfigService.getUmsLoginUrl();

      // generate password hash
      const pwHash = this.getPasswordHash(password);

      // get tenant id
      const tenantId = this.loginConfigService.getTenantId();

      // build body for request
      const body: any = {
        username: username,
        password: pwHash
      };

      // add tenant id to request body
      if (tenantId) {
        body.tenantId = tenantId;
      }

      setTimeout(() => {
        this.http.put(loginApiUrl, body)
          .subscribe(
            response => {
              const resp: any = response;

              // don't trust the http response code. could still be a failed request!
              if (resp.message) {
                reject(resp);
              }

              LoginService.userObject = new UserLoginModel(
                username,
                resp.token,
                resp.userId,
                resp.validUntil,
                resp.permissions,
                resp.user.tosAccepted,
                resp.user.data,
              );

              if (remember) {
                this.persistUserObject();
              }


              // force pw change when initial pw used and not allowed by config
              password === this.initialPw
                && !this.loginConfigService.isInitialPasswordAllowed()
                && this.registryService.set('forcePwChange', true);


              setTimeout(() => this.loginEventService.onAfterLogin().emit({tokenLogin: false}), 1500);
              resolve(LoginService.userObject);
            },
            response => {
              const res = response.error && typeof response.error === 'object' ? response.error : response;
              if (res.message) {
                reject(res);
              } else {
                reject({
                  message: '',
                  code: 99999
                });
              }
            });

      }, 500);
    });
  }

  /**
   * Sends a request to reset the password
   *
   * @param username
   * @param captcha
   * @param locale
   * @returns {Promise<any>}
   */
  public resetPassword(username: string, captcha: string, locale: string = 'de_DE'): Promise<any> {
    return new Promise((resolve, reject) => {
      const resetPasswordUrl = this.loginConfigService.getResetPasswordSubmitUrl();

      const body = {
        email: username, captcha, locale
      };

      this.http.post(resetPasswordUrl, body)
        .subscribe(
          response => {
            const resp: any = response;
            if (resp && resp.success) {
              resolve(resp);
            } else {
              reject(new Error(resp.message || 'Unknown error'));
            }
          },
          error => {
            const errorMessage = error.error && error.error.message ? error.error.message : 'Network error';
            reject(new Error(errorMessage));
          }
        );
    });
  }

  /**
   * Logs a user into the ums
   *
   * @param username
   * @param password
   * @param remember
   * @returns {Promise<any>}
   */
  public tokenLogin(token: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = this.authUriService.getAuthHeaders();
      headers['X-Api-Token'] = token;

      // get login url via login config service
      const loginApiUrl = this.loginConfigService.getUmsLoginUrl();

      setTimeout(() => {
        this.http.get(loginApiUrl, {headers})
          .subscribe(
            response => {
              const resp: any = response;

              // don't trust the http response code. could still be a failed request!
              if (resp.message) {
                reject(resp);
                return;
              }

              LoginService.userObject = new UserLoginModel(
                resp.user.email,
                resp.token,
                resp.userId,
                resp.validUntil,
                resp.permissions,
                resp.user.tosAccepted,
                resp.user.data
              );

              setTimeout(() => this.loginEventService.onAfterLogin().emit({tokenLogin: true}), 1500);
              resolve(LoginService.userObject);
            },
            (response: any) => {

              if (response.message) {
                reject(response);
              } else {
                reject({
                  message: '',
                  code: 99999
                });
              }
            });

      }, 500);
    });
  }

  /**
   * Logs out the user
   *
   * @returns {Promise<any>}
   */
  public logout(): Promise<any> {
    // delete the login user object
    LoginService.userObject = null;

    // persist the change in local storage
    this.deleteUserObject();

    setTimeout(() => this.loginEventService.onAfterLogout().emit(), 1500);

    // immediately resolve promise
    // NOTE: maybe we will implement an api logout
    return Promise.resolve();
  }

  /**
   * Accepts the terms of service
   *
   * @returns {Promise<any>}
   */
  public acceptTos(): Promise<any> {
    return new Promise((resolve, reject) => {
      const tosAcceptUrl = this.loginConfigService.getUmsTosAcceptUrl(this.getUser().getToken());

      if (!tosAcceptUrl) {
        console.warn('unable to fetch tos accept url. skipping tos accept');
        return;
      }

      this.http.get(tosAcceptUrl)
        .subscribe(
          response => resolve(response),
          error => reject(error)
        );
    });
  }

  /**
   * Asks api if user is still logged in
   * and refreshes the user session
   *
   * @returns {Promise<boolean>}
   */
  public heartbeat(): Promise<boolean> {
    if (!this.isLoggedIn()) {
      return Promise.resolve(true);
    }

    const authHeaders = this.authUriService.getAuthHeaders();
    const heartbeatApiUrl = this.loginConfigService.getUmsHeartbeatUrl();

    if (!heartbeatApiUrl) {
      console.warn('unable to fetch heartbeat api url. skipping heartbeat check');
      return Promise.resolve(true);
    }

    return new Promise((resolve, reject) => {
      this.http.get(heartbeatApiUrl, {headers: authHeaders})
        .subscribe((response: any) => {

            if (response.code && response.code === 401) {
              resolve(false);
              return;
            }

            if (!response.success) {
              resolve(false);
              return;
            }

            // refresh browser session
            LoginService.userObject = new UserLoginModel(
              response.user.email,
              response.token,
              response.userId,
              response.validUntil,
              response.permissions,
              response.user.tosAccepted,
              response.user.data
            );

            // user object was persisted?
            if (this.isUserPersisted()) {
              // refresh persisted user object
              this.persistUserObject();
            }

            // determine if permissions are unchanged
            const permissionsUnchanged =
              JSON.stringify(LoginService.userObject.getPermissions()) ===
              JSON.stringify(response.permissions);

            // no need to change the user object
            if (permissionsUnchanged) {
              resolve(true);
              return;
            }

            // triggers "user change" event
            this.layoutService.setUser({
              username: response.user.email
            });

            resolve(true);
        },
        error => {
          reject(error);
        });
    });
  }
}
